.App-Increase {
  text-align: center;
  background: url("https://landingpage-uat.tnex.vn/img/bg.png");
  background-size: 100% 100%;
  height: 100vh;
  overflow-y: auto;
  font-family: Quicksand, serif;
  font-size: 1rem;
}
p{
  font-family: Quicksand, serif !important;
  font-size: 1rem !important;
}
.content-increase{
  background-color: rgba(247, 248, 249, 0.65);
  padding: 16px;
  margin: 16px 20px;
  border-radius: 20px;
}
.fw-500{
  font-weight: 500;
}
.Mui-required > span,.required-text{
  color: red;
}
.customInput{
  width: 100% !important;
}

.customInput >div::after,.customInput >div::before,.customInput >div>div::after,.customInput >div>div::before{
  border: none !important;
}
.confirm_label{
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: justify;
}
.text-al-left{
  text-align: left !important;
}
.w100{
  width: 100%;
}
.w90{
  width: 90%;
}
.mt-10{
  margin-top: 10px !important;
}
.mt-20{
  margin-top: 20px !important;
}
.mb-20{
  margin-bottom: 20px !important;
}
.customInput > .MuiInputBase-root,.customInput >div>div{
  background-color: white !important;
}
.MuiFilledInput-root{
  border: 1px solid;
  border-radius: 4px;
}
.MuiFilledInput-root{
  border-color: #ffffff;
}
.MuiFilledInput-root.Mui-focused{
  background-color: #fff;
  box-shadow: rgba(25,118,210,0.25) 0 0 0 2px;
  border-color: #1976d2 !important;
}

.sliderCustom{
  height: 8px !important;
}

.h-40{
  height: 40px;
}
.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 32px 0 #fff, -32px 0 #fff;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF;
  }
  50% {
    background-color: #FFF;
    box-shadow: 32px 0 #FFF2, -32px 0 #FFF2;
  }
  100% {
    background-color: #FFF2;
    box-shadow: 32px 0 #FFF, -32px 0 #FFF2;
  }
}

.float-left{
  float: left;
}
.float-right{
  float: right;
}
.required::after{
  content: "*";
  color: red;
}
@media only screen and (min-width: 900px) {
  .content-increase{
    max-width: 850px;
  }
}
@media only screen and (min-width: 600px) {
  .content-increase {
    padding-left: 24px;
    padding-right: 24px;
  }
  .lr-50{
    width: 49% !important;
    margin-right: 1%;
  }
  .rr-50{
    width: 49% !important;
    margin-left: 1%;
  }

}

@media only screen and (max-width: 599px) {
  .content-increase{
    padding-left: 15px;
    padding-right: 15px;
  }
  .md-100{
    width: 100% !important;
  }

}

